// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { Button } from 'react-bootstrap';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdateUserGoals } from '../../../actions/user';
import { BlockRouteTransitions, UnBlockRouteTransitions } from '../../../actions/router';
// Constants
import { TIME_TRACKING_NOTES } from '../../../constants/templates';
import {
  isObjectsEqual,
  validateActivityTimeRequired,
} from '../../../services/helpers';
import { createActivity, getActivity, updateActivity } from '../../../services/patient';
import { getGoals } from '../../../services/goals';
import { updateUserInfoInStorage } from '../../../services/userStorage';
// Views
import EditableInput from '../../base/EditableInput';
import CompoundProgressBar from '../../base/CompoundProgressBar';
import {
  AUDITING_TITLE_OPTIONS, NOTIFICATION_TYPE, USER_ROLES, DATE_FORMAT,
} from '../../../constants/constants';
// Components
import { withRouter } from '../../shared/WithRouter';
// Local Constans
const ELEMENT_NAME_TO_BLOCK = 'isExistingActivity';

export class Activity extends Component {
  constructor(props) {
    super(props);

    const activity = props.activity || {};

    this.state = {
      loading: false,
      activity,
      opened: activity.opened || !!activity.isNew,
      titleValidation: {
        valid: true,
        message: '',
      },
      isDataChanged: false,
      isNoteChanged: false,
      isDataRemoved: false,
    };

    this.refs = {};

    this.promises = {};

    this.saveRef = this.saveRef.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.save = this.save.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.updateNotesValue = this.updateNotesValue.bind(this);
    this.doActivityUpdateSave = this.doActivityUpdateSave.bind(this);
    this.loadActivity = this.loadActivity.bind(this);
    this.saveActivity = this.saveActivity.bind(this);
    this.deleteActivity = this.deleteActivity.bind(this);
  }

  componentDidMount() {
    ReactTooltip.rebuild();
    // save data if a user reloads a page
    window.addEventListener('beforeunload', this.saveActivity);
  }

  componentDidUpdate(prevProps) {
    const { activity = {}, blockTransitions, unblockTransitions } = this.props;
    const { isDataChanged } = this.state;
    ReactTooltip.hide();

    if (activity !== prevProps.activity) {
      this.setState({
        activity,
        opened: activity.opened || !!activity.isNew,
      });
    }

    if (isDataChanged && !activity.isNew) {
      blockTransitions(ELEMENT_NAME_TO_BLOCK);
    } else {
      unblockTransitions(ELEMENT_NAME_TO_BLOCK);
    }
  }

  componentWillUnmount() {
    const { unblockTransitions } = this.props;
    unblockTransitions(ELEMENT_NAME_TO_BLOCK);

    if (this.promises.length) {
      Object.keys(this.promises).forEach((key) => {
        this.promises[key].cancel();
      });
    }

    // save data if a user clicks the back button (except if it is removed)
    if (this.state.isDataChanged && !this.state.isDataRemoved) {
      this.saveActivity();
    }

    window.removeEventListener('beforeunload', this.saveActivity);
  }

  getGoalsData = () => {
    const {
      user: { progressMap }, params: { tenant: tenantUrl },
      showNotification, updateGoals,
    } = this.props;

    this.setState({
      loading: true,
    });

    const promiseName = 'getGoals';
    const getGoalsRequest = getGoals();
    const getGoalsPromise = getGoalsRequest.promise;

    this.promises[promiseName] = getGoalsPromise;

    getGoalsPromise.then((data) => {
      delete this.promises[promiseName];

      if (!isObjectsEqual(progressMap, data)) {
        updateGoals(data);
        updateUserInfoInStorage({ progressMap: data }, tenantUrl);
      }

      this.setState({
        loading: false,
      });
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      this.setState({
        loading: false,
      });

      showNotification({
        message: 'Could not load goals data',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  updateDirectContact = () => {
    const { activity, activity: { permissions }, loading } = this.state;
    const { disabled } = this.props;
    if ((permissions && !permissions.canBeDeleted) || loading || disabled) {
      return;
    }

    this.setState(state => ({
      ...state,
      isDataChanged: true,
      activity: {
        ...state.activity,
        directContactIsMade: !state.activity.directContactIsMade,
      },
    }), !activity.isNew ? this.saveActivity : null);
  };

  saveRef(name, ref) {
    this.refs = {
      ...this.refs,
      [name]: ref,
    };
  }

  toggleOpen() {
    const { opened } = this.state;

    if (!opened) {
      this.loadActivity();
    }

    this.setState({
      opened: !opened,
    });
  }

  validateAllFields() {
    let result = true;

    Object.keys(this.refs).forEach((key) => {
      if (key !== 'title') {
        result = this.refs[key].validate() && result;
      } else if (!this.refs[key].props.value) {
        this.setState({
          titleValidation: {
            valid: false,
            message: 'Is required',
          },
        });
        result = false;
      }
    });

    return result;
  }

  loadActivity(callback) {
    const { showNotification, activityID } = this.props;

    if (!activityID || activityID < 0) {
      return; // activity has not yet been saved, no notes to load
    }

    this.setState({
      loading: true,
    });

    const promiseName = 'getActivity';
    const getActivityRequest = getActivity(activityID);
    const getActivityPromise = getActivityRequest.promise;
    this.promises[promiseName] = getActivityRequest;

    getActivityPromise.then((data) => {
      delete this.promises[promiseName];

      if (callback) {
        this.setState(state => ({
          ...state,
          loading: false,
          activity: {
            ...state.activity,
            notes: data.notes,
          },
        }), callback);
      } else {
        this.setState(state => ({
          ...state,
          loading: false,
          activity: {
            ...state.activity,
            notes: data.notes,
          },
        }));
      }
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      this.setState({
        loading: false,
      });

      showNotification({
        message: 'Could not load notes for this activity',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  save() {
    const { showNotification, user: { role } } = this.props;
    if (this.state.loading || !this.validateAllFields()) {
      return;
    }

    this.setState({
      loading: true,
    });

    const promiseName = 'createActivity';

    const createActivityRequest = createActivity(this.props.patientId, {
      ...this.state.activity,
    });

    const createActivityPromise = createActivityRequest.promise;
    this.promises[promiseName] = createActivityRequest;

    createActivityPromise.then((data) => {
      delete this.promises[promiseName];

      this.setState(state => ({
        loading: false,
        activity: {
          ...state.activity,
          id: data.id,
          isNew: undefined,
          cnName: data.cnName,
        },
      }), this.props.loadCallback);

      if (role !== USER_ROLES.ADMIN) {
        this.getGoalsData();
      }
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      this.setState({
        loading: false,
      });

      if (error.status === 401) {
        return;
      }

      showNotification({
        message: 'An error occurred while attempting to save this activity.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  updateValue(key, value, isValid) {
    if (!(key === 'title' && (!value || value.length > 255))) {
      this.setState(state => ({
        activity: {
          ...state.activity,
          [key]: isValid ? +value : value,
        },
        titleValidation: {
          valid: true,
          message: '',
        },
        isDataChanged: true,
      }), this.saveActivity);
    } else if (!value) {
      this.setState({
        titleValidation: {
          valid: false,
          message: 'Is required',
        },
      });
    } else if (value.length > 255) {
      this.setState({
        titleValidation: {
          valid: false,
          message: 'Value length exceeds 255 symbols',
        },
      });
    }
  }

  updateNotesValue(event) {
    const eventTarget = event.target || {};

    this.setState(state => ({
      activity: {
        ...state.activity,
        [eventTarget.name]: eventTarget.value,
      },
      isDataChanged: true,
      isNoteChanged: true,
    }));
  }

  saveActivity() {
    const { activity, isNoteChanged } = this.state;

    if (!isNoteChanged && (!activity.notes || activity.notes.length === 0)) {
      this.loadActivity(this.doActivityUpdateSave);
    } else {
      this.doActivityUpdateSave();
    }
  }

  doActivityUpdateSave() {
    const { activity, isDataChanged } = this.state;
    const { showNotification, user: { role } } = this.props;
    if (activity.isNew || !this.validateAllFields() || !isDataChanged) {
      return;
    }

    this.setState({
      loading: true,
    });

    const promiseName = 'updateActivity';
    const updateActivityRequest = updateActivity(activity);
    const updateActivityPromise = updateActivityRequest.promise;
    this.promises[promiseName] = updateActivityRequest;

    updateActivityPromise.then(() => {
      delete this.promises[promiseName];

      this.setState({
        loading: false,
        isDataChanged: false,
        isNoteChanged: false,
      });

      this.props.changeCallback(activity);

      if (role !== USER_ROLES.ADMIN) {
        this.getGoalsData();
      }
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      this.setState({
        loading: false,
      });

      showNotification({
        message: 'An error occurred while attempting to update this activity.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  deleteActivity() {
    this.setState(state => ({
      ...state,
      isDataRemoved: true,
    }));
    this.props.deleteCallback(this.state.activity);
  }

  render() {
    const {
      loading, activity, opened, titleValidation, activity: { directContactIsMade },
    } = this.state;
    const { user, disabled } = this.props;
    const activityPermissions = activity.permissions || {};
    const canBeDeleted = (activityPermissions.canBeDeleted !== false);
    const canTimeBeEdited = (activityPermissions.canTimeBeEdited !== false);
    const buttons = [];
    let notes;
    let controls;
    let billableBlock;

    if (opened) {
      notes = (
        <div className="activity-notes" data-test="activity_notes">
          <div className="d-flex d-flex-center-between px-3">
            <b>Notes</b>
            <div className="text-left" style={{ width: 200 }}>
              <Select
                name="template"
                className="border-bottom-0"
                placeholder="Add a custom template"
                searchable
                clearable={false}
                options={TIME_TRACKING_NOTES}
                onChange={ev => this.updateNotesValue(
                  { target: { value: ev && `${activity.notes && activity.notes.length ? `${activity.notes}\n\n` : ''}${ev.value}`, name: 'notes' } },
                )}
                data-test="activity_templateSelect"
              />
            </div>
          </div>
          <div className="position-relative box box-full">
            <textarea
              readOnly={(!activity.isNew) && (activity.billed || !canBeDeleted)}
              name="notes"
              value={activity.notes || ''}
              onChange={this.updateNotesValue}
              disabled={disabled}
              data-test="activity_notesField"
            />
          </div>
        </div>
      );

      if ((canBeDeleted || activity.isNew) && user.role === USER_ROLES.ADMIN) {
        buttons.push(<Button
          size="sm"
          key="deleteEntry"
          variant="light"
          className="ml-2"
          onClick={this.deleteActivity}
          disabled={disabled}
          data-test="activity_deleteButton"
        >
          Delete entry
        </Button>);
      }

      buttons.push(<Button
        size="sm"
        key="clearNote"
        variant="light"
        className="ml-2"
        disabled={!activity.notes || disabled}
        onClick={() => this.updateNotesValue({ target: { name: 'notes', value: '' } })}
        data-test="activity_clearActivityNotes"
      >
        Clear note
      </Button>);

      if (!activity.isNew) {
        buttons.push(<Button
          size="sm"
          key="saveNote"
          className="ml-2"
          onClick={() => this.saveActivity()}
          data-test="activity_saveActivityNotes"
          disabled={disabled}
        >
          Save note
        </Button>);
      }

      if (activity.isNew) {
        buttons.push(<Button
          size="sm"
          key="saveEntry"
          className="ml-2"
          onClick={this.save}
          disabled={disabled}
          data-test="activity_saveButton"
        >
          Save entry
        </Button>);
      }

      controls = <div className="d-flex justify-content-end align-items-center py-3 px-4">{buttons}</div>;
    }

    if (activity.billed) {
      billableBlock = <div className="activity-label-success">Billed</div>;
    } else if (activity.billable) {
      billableBlock = <div className="activity-label-info">Billable</div>;
    }

    return (
      <div className="activity">
        <div className="activity-header pl-2 pr-3 py-2" data-test="activity_header">
          <div className="activity-title editable-input" data-tip={activity.title} data-test="activity_reasonsSelect" data-for="tooltip-activity-name">
            { !titleValidation.valid && <div className="validation-label">{titleValidation.message}</div>}
            <Select
              className={!titleValidation.valid ? 'error' : ''}
              name="reasons-select"
              placeholder={activity.title || ''}
              clearable={false}
              value={activity.title}
              options={AUDITING_TITLE_OPTIONS}
              onChange={(val) => { this.updateValue('title', val.value); }}
              onBlur={(e) => {
                if (e.target.value) {
                  this.updateValue('title', e.target.value);
                }
              }}
              disabled={activity.billed || loading || disabled}
              ref={this.saveRef.bind(this, 'title')}
              required
              data-test="activity_reasonsSelectField"
            />
          </div>
          <div className="activity-cn-name">{activity.cnName}</div>
          <div className="activity-date">
            {moment(activity.date, DATE_FORMAT.FULL_SERVER_WITH_TIME)
              .format(DATE_FORMAT.FULL_WITH_TIME_IN_12_HOURS)}
          </div>
          <div className="direct-contact" onClick={this.updateDirectContact}>
            <div data-test="activity_directContact" className={`direct-contact-toggle ${directContactIsMade ? 'active' : ''}`} />
            <div className="direct-contact-title">{directContactIsMade ? 'Direct' : 'Not direct'}</div>
          </div>
          <div className="activity-label">
            {billableBlock}
          </div>
          <div className="ccm-container activity-ccm-container">
            <div data-test="activity_durationField">
              <EditableInput
                ref={this.saveRef.bind(this, 'duration')}
                wrapperClassName="duration"
                validationCallback={validateActivityTimeRequired}
                type="text"
                submitCallback={this.updateValue}
                fieldKey="duration"
                initialValue={activity.duration}
                disabled={!canTimeBeEdited || loading || disabled}
                maxLength="4"
                data-test="activity_timeInput"
              />
              <div className="minutes">min</div>
            </div>
          </div>
          <CompoundProgressBar
            duration={activity.duration}
            timePeriod={this.props.maxCcmMtd}
          />
          <div
            onClick={this.toggleOpen}
            className={`open-toggle ${opened ? 'opened' : 'closed'}`}
            data-test="activity_toggle"
          />
        </div>
        {notes}
        {controls}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
    updateGoals: value => dispatch(UpdateUserGoals(value)),
    blockTransitions: elem => dispatch(BlockRouteTransitions(elem)),
    unblockTransitions: elem => dispatch(UnBlockRouteTransitions(elem)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Activity));
